import React from "react"
import parse from "html-react-parser"

const VideoNoControls = ({ video, classNames }) => (
  <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className={`video-nc-container ${classNames ? classNames : ''}`}>
    {parse(video)}
  </div>
)

export default VideoNoControls
